import React from "react"
import { Link } from "gatsby"
import { Container, Header } from "semantic-ui-react"

import Layout from "../components/layout-desktop"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container text>
      <Header as="h1">Page not found</Header>
      <p>Nothing to see here; the page you are looking for doesn't exist.</p>
      <Link to="/">Back to the home page</Link>
    </Container>
  </Layout>
)

export default NotFoundPage
